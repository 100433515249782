import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private msalService : MsalService) {
    localStorage.removeItem('InvoiceConLS');
    localStorage.removeItem('urlRedirectLogin');
  }

  ngOnInit() {
    this.msalService.logout();
  }

}
