@if (showLoader) {
<div class="overlay">
  <div class="center">
    <mat-spinner></mat-spinner>
  </div>
</div>
}

<div class="header">
  <app-header (themeChange)="mudarTema($event)"></app-header>
</div>

<div class="container">
  @if (!isIframe) {
  <router-outlet></router-outlet>
  }
</div>
