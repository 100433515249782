<div class="container" fxLayout="column" style="height: 100%; width: 100%">
  <mat-expansion-panel class="card" [expanded]="true">
    <mat-panel-title>Imagens dos Produtos</mat-panel-title>
    @if (produtosFiltrados) {
    <form action="" fxLayout="row">
      <mat-form-field fxFlex="30%">
        <input
          matInput
          placeholder="Pesquisar"
          value=""
          (keyup)="applyFilter($event.target.value)"
        />
        <button
          mat-flat-button
          matSuffix
          disabled
          aria-label="search"
          class="btn-suffix"
        >
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </form>
    } @if (produtosFiltrados) {
    <div style="overflow-x: auto">
      <table
        mat-table
        matSort
        id="grid-home"
        style="width: 100%"
        [dataSource]="produtosFiltrados"
      >
        <ng-container matColumnDef="ProdutoId" style="padding-left: 330px">
          <th
            class="reducer"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            matTooltip="Produto"
            style="
              width: 5%;
              padding-right: 5px;
              margin-right: 5px !important;
              padding-left: 15px !important;
            "
          >
            Produto
          </th>
          <td
            class="reducer"
            mat-cell
            *matCellDef="let element"
            (click)="openProdutoImagem(element.produtoId)"
            matTooltip="{{ element.produtoId }}"
            style="
              padding-right: 5px;
              margin-right: 5px !important;
              padding-left: 15px !important;
            "
          >
            {{ element.produtoId }} - {{ element.descricao }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="row-table"
        ></tr>
      </table>
    </div>
    }
  </mat-expansion-panel>
</div>
