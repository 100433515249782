import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss']
})
export class PrincipalComponent {

  constructor(private httpClient: HttpClient) { }

  onConfigClick(): void {
    this.httpClient.get(`${env.API_ENDPOINT}/api/conf/database/USERS_Cast`).subscribe({
      next: response => {
        console.log(response);
      },
      error: error => {
        console.error(error);
      }
    });
  }

  onReplicacaoClick(): void {
    this.httpClient.get(`${env.API_ENDPOINT}/api/v3/replicate/run`).subscribe({
      next: response => {
        console.log(response);
      },
      error: error => {
        console.error(error);
      }
    });
  }

  onPreCadastroClick(): void {
    this.httpClient.get(`${env.API_ENDPOINT}/api/sync/precadastro`).subscribe({
      next: response => {
        console.log(response);
      },
      error: error => {
        console.error(error);
      }
    });
  }

  onPedidoClick(): void {
    this.httpClient.get(`${env.API_ENDPOINT}/api/v3/integrate/pedidos`).subscribe({
      next: response => {
        console.log(response);
      },
      error: error => {
        console.error(error);
      }
    });
  }

  onListJobs(): void {
    this.httpClient.get(`${env.API_ENDPOINT}/api/v2/jobs`).subscribe({
      next: response => {
        console.log(response);
      },
      error: error => {
        console.error(error);
      }
    });
  }

  onStopJobs(): void {
    this.httpClient.delete(`${env.API_ENDPOINT}/api/v2/jobs`).subscribe({
      next: response => {
        console.log(response);
      },
      error: error => {
        console.error(error);
      }
    });
  }

  onRunJobs(): void {
    this.httpClient.post(`${env.API_ENDPOINT}/api/v2/jobs`, {}).subscribe({
      next: response => {
        console.log(response);
      },
      error: error => {
        console.error(error);
      }
    });
  }
}
