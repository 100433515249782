import { Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';

export interface ConfirmacaoExclusaoDialogData {
  usuario: string;
  delecaoUsuario: DelecaoUsuario;
}

export interface DelecaoUsuarioProps {
  descricao: string;
  exibicao: boolean;
}

export interface DelecaoUsuario {
  atendimento: DelecaoUsuarioProps;
  cotacao: DelecaoUsuarioProps;
  preCadastroParceiro: DelecaoUsuarioProps;
}

@Component({
  selector: 'app-confirmacao-exclusao-dialog',
  templateUrl: './confirmacao-exclusao-dialog.component.html',
})
export class ConfirmacaoExclusaoDialogComponent {
  public delecoes: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmacaoExclusaoDialogData,
    public dialogRef: MatDialogRef<ConfirmacaoExclusaoDialogData>,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/img/close.svg')
    );

    // Cria lista de documentos que serão deletados para exibição ao usuário
    for (const prop in data.delecaoUsuario) {
      if (data.delecaoUsuario[prop].exibicao) {
        this.delecoes.push(data.delecaoUsuario[prop].descricao);
      }
    }
  }

  onCancelar(): void {
    this.dialogRef.close(null);
  }

  onConfirmar(): void {
    this.dialogRef.close(this.data);
  }
}
