<div class="container" fxLayout="column" style="height: 100%; width: 100%;">
  <mat-expansion-panel class="card" [expanded]="true">
  <mat-panel-title>Opções</mat-panel-title>
  <section>
    <button mat-raised-button color="accent" (click)="onReplicacaoClick()">Replicação</button>
    <button mat-raised-button color="accent" (click)="onPreCadastroClick()">Integração pré-cadastro</button>
    <button mat-raised-button color="accent" (click)="onPedidoClick()">Integração pedidos</button>
    <button mat-raised-button color="accent" (click)="onConfigClick()">Testar configuração</button>
    <button mat-raised-button color="accent" (click)="onStopJobs()">Parar jobs</button>
    <button mat-raised-button color="accent" (click)="onRunJobs()">Iniciar jobs</button>
    <button mat-raised-button color="accent" (click)="onListJobs()">Listar jobs</button>
  </section>
  </mat-expansion-panel>
</div>
