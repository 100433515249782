<div class="mat-dialog-container">
  <!-- Dialog header -->
  <div fxLayout="row" fxLayoutGap="10px">
    <h2 mat-dialog-title style="width: 100%; text-align: start">Atenção!!</h2>
  </div>

  <!-- Modal content -->
  <mat-dialog-content>
    <div fxLayout="column">
      <div fxLayout="column" style="padding-bottom: 20px">
        <!-- <mat-label style="color: var(--color-fg-1) !important; font-size: 14px !important;">Usuário</mat-label> -->
        <span>
          Ao excluir, todos os dados do usuário
          <strong>{{ data.usuario }}</strong> serão deletados! Confirma a ação?
        </span>
        @if (delecoes.length > 0) {
        <div>
          <br />
          <span>Dados do usuário a serem deletados:</span>
          <ul>
            @for (delecao of delecoes; track $index) {
            <li>
              {{ delecao }}
            </li>
            }
          </ul>
        </div>
        }
      </div>
    </div>
  </mat-dialog-content>

  <!-- Modal actions -->
  <mat-dialog-actions fxLayoutAlign="end center">
    <div fxLayout="row">
      <button
        mat-flat-button
        class="btn btn-primary"
        (click)="onConfirmar()"
        cdkFocusInitial
      >
        CONFIRMO
      </button>
      <button mat-flat-button class="btn btn-danger" (click)="onCancelar()">
        CANCELAR
      </button>
    </div>
  </mat-dialog-actions>
</div>
