export class ErroItem {
    /**
     * Classe ErroItem contém os atributos para transmitir informações da
     * chamada de erro para a página de erro;
     * Nenhum parâmetro é obrigatório
     */
    cod: string; // Código de erro
    mensagem: string; // Mensagem enviada de erro que será exibida na página de erro
    tipo: string; // Tipo de erro (Login, ERP, Rota, etc...) -> Verificar se faz sentido na remodelagem
    solucao: string; // Mensagem sugerindo uma resolução do problema
}
