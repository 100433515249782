<div class="container" fxLayout="column" style="height: 100%; width: 100%">
  <mat-expansion-panel class="card" [expanded]="true">
    <mat-panel-title>Formulários</mat-panel-title>
    <form>
      <div fxLayout="row">
        <mat-form-field fxFlex="60%">
          <input
            matInput
            placeholder="Pesquisar"
            value=""
            (keyup)="applyFilter($event.target.value)"
          />
          <button
            mat-flat-button
            matSuffix
            disabled
            aria-label="search"
            class="btn-suffix"
          >
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
        <div fxFlex="40%" fxLayoutAlign="end center">
          <button
            mat-stroked-button
            class="row-button"
            (click)="onNew()"
            fxFlexAlign="end"
            align="end"
          >
            <mat-icon>note_add</mat-icon>
            <span style="padding-left: 5px"><b>NOVO</b></span>
          </button>
        </div>
      </div>
    </form>
    <div fxLayout="row">
      <div fxFlex="30%"><b>Formulário</b></div>
      <div fxFlex="29%"><b>Descrição</b></div>
      <div fxFlex="20%"><b>Data Modificação</b></div>
      <div fxFlex="11%"><b>Ativo?</b></div>
      <div fxFlex="10%" fxLayoutAlign="end"></div>
    </div>
    <!-- Formulários -->
    @for (formulario of formulariosFiltrados; track $index) {
    <mat-card appearance="outlined" class="mb-10 color-inv">
      <mat-card-content>
        <div fxLayout="row" style="font-weight: bold; margin-top: 10px">
          <div fxFlex="30%">{{formulario.dadosGerais.id}}</div>
          <div fxFlex="40%">{{formulario.dadosGerais.nome}}</div>
          <div fxFlex="20%">{{toDateBr(formulario)}}</div>
          <div fxFlex="10%">{{formulario.dadosGerais.ativo ? 'Sim':'Não'}}</div>
          <div fxFlex="10%" fxLayoutAlign="end">
            <button mat-stroked-button (click)="onEdit(formulario)">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    }
  </mat-expansion-panel>
</div>
