import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class LoaderService {
  private showLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  public show() {
    this.showLoader.next(true);
  }

  public hide() {
    this.showLoader.next(false);
  }

  public getLoader(): BehaviorSubject<boolean> {
    return this.showLoader;
  }
}