export const environment = {
  production: false,
  AMBIENTE: 'DEV',
  AMBIENTE_TOOLTIP: 'Ambiente de desenvolvimento',
  API_ENDPOINT: 'https://api.dts.salescon.com.br/develop',
  API_SCOPE: 'https://salescon-api-dev.azurewebsites.net/user_impersonation',
  AZURE_AD_CLIENT_ID: '469fef17-aa68-4950-8ab6-52b51388a3e5',
  AZURE_ID_AUTHORITY: 'https://login.microsoftonline.com/fcaa42a5-75e0-430e-8bdd-deb0c4e189a0/',
  REDIRECT_URI: 'https://adm.dev.salescon.com.br/develop/'
};
