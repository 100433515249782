import { Injectable } from '@angular/core';

import moment, { Moment } from 'moment';
import momentTimezone from 'moment-timezone';

// Exportação de tipagens
export {
  Moment,
};

/**
 * Serviço para tratamento de datas de forma genérica
 */
@Injectable({
  providedIn: 'root'
})
export class TimeHandlerService {

  /**
   * Recebe dois parâmetros para gerar datas e calcular a diferença entre elas
   * @param dateA
   * @param dateB
   * @returns Objeto da diferença entre as datas
   */
  private _calculateDiff(dateA: any, dateB: any): moment.Duration {
    const start = moment(dateA);
    const ends = moment(dateB);
    return moment.duration(ends.diff(start));
  }

  /**
   * Diferença em minutos entre duas datas
   * @param dateA
   * @param dateB
   * @returns Diferença em minutos
   */
  public diffInMinutes(dateA: any, dateB: any): number {
    const diff = this._calculateDiff(dateA, dateB);
    const diffInMinutes = diff.asMinutes();
    return diffInMinutes;
  }

  /**
   * Diferença em segundos entre duas datas
   * @param dateA
   * @param dateB
   * @returns Diferença em minutos
   */
  public diffInSeconds(dateA: any, dateB: any): number {
    const diff = this._calculateDiff(dateA, dateB);
    const diffInSeconds = diff.asSeconds();
    return diffInSeconds;
  }

  /**
   * Diferença em dias de duas datas
   * @param dateA Data menor
   * @param dateB Data maior
   * @returns Diferença em dias
   */
  public diffInDays(dateA: any, dateB: any): number {
    const start = moment(dateA);
    const ends = moment(dateB);
    const diff = moment.duration(ends.diff(start));
    return diff.asDays();
  }

  /**
   * Retorna data com fuso horário
   * @param date data
   * @returns Objeto representando a data gerada
   */
  public getTimezoneDate(date?: any, timezone?: string): moment.Moment {
    const tz = timezone || momentTimezone.tz.guess();
    return momentTimezone.tz(date, tz);
  }

  /**
   * Formata a data para o padrão informado por parametro
   * @param date data
   * @param format formato
   * @returns data formatada
   */
  public formatDate(date: string, format: string): string {
    return moment(date).format(format);
  }

  /**
 * Formata a data para o padrão informado por parametro
 * @param date data
 * @param format formato
 * @returns data formatada
 */
  public formatDateUtc(date: string, format: string): string {
    return moment.utc(date).format(format);
  }

  /**
   * Formata a data atual para o padrão informado por parâmetro
   * @param format formato
   * @returns data formatada
   */
  public getCurrentDateFormatted(format: string): string {
    return moment().format(format);
  }

  /**
   * Recupera a data atual para isoString
   */
  public get nowIsoString() {
    return moment().toISOString();
  }


  public get now() {
    return moment();
  }

  /**
   * Retorna primeiro dia do mês atual
   * @param date Data
   * @returns
   */
  public getFirstDayOfTheMonth(date?: string): moment.Moment {
    let dateObject = new Date();
    if (date) {
      dateObject = new Date(date);
    }
    const dateTz = momentTimezone.tz(dateObject, momentTimezone.tz.guess());
    return dateTz.local().startOf('month');
  }

  /**
   * Retorna último dia do mês atual
   * @param date Data
   * @returns
   */
  public getLastDayOfTheMonth(date?: string): moment.Moment {
    let dateObject = new Date();
    if (date) {
      dateObject = new Date(date);
    }
    const dateTz = momentTimezone.tz(dateObject, momentTimezone.tz.guess());
    return dateTz.local().endOf('month');
  }

  /**
   * Retorna o ultimo mês/período baseado no data informada
   * @param date Data
   * @returns Mês/Período anterior
   */
  public getlastMonth(date: Moment): Moment {
    const dateTz = momentTimezone.tz(date, momentTimezone.tz.guess());
    return moment(dateTz).subtract(1, 'months');
  }

  /**
   * Retorna o próximo mês/período baseado no data informada
   * @param date Data
   * @returns Próximo Mês/Período
   */
  public getNextMonth(date: Moment): Moment {
    const dateTz = momentTimezone.tz(date, momentTimezone.tz.guess());
    return moment(dateTz).add(1, 'months');
  }

  /**
   * Faz a comparação entre duas datas
   * @param isAfter Caso queira verificar se a primeira data é depois da primeira
   * passar como true, do contrário passar false
   * @param firstDate Data 1
   * @param secondDate Data 2
   * @returns Retorna true/false baseado na comparação escolhida
   */
  public compareDates(compareType: CompareMomentEnum, firstDate: Moment, secondDate: Moment): boolean {
    if (compareType === CompareMomentEnum.after) {
      return firstDate.isAfter(secondDate);
    }

    if (compareType === CompareMomentEnum.before) {
      return secondDate.isAfter(firstDate);
    }

    if (compareType === CompareMomentEnum.same) {
      return firstDate.isSame(secondDate);
    }
  }

  /**
   * Retorna uma data sempre na próxima meia hora exata
   * Caso fornecido 10:31, retorna 11:00
   * @param date Data - ISO format
   * @returns Data
   */
  public getNextHalfHour(date: Moment): Moment {
    const remainder = 30 - (date.minute() % 30);
    const dateTime = moment(date).add(remainder, 'minutes');
    return dateTime;
  }

  /**
   * Adiciona um valor determinado a certa data
   * @param type Tipo de adição a ser realizada, checar o TimeTypesEnum
   * @param qtd Quantidade a ser adicionada
   * @param date Data a ser incrementada
   * @returns Data incrementada
   */
  public add(type: TimeTypesEnum, qtd: number, date: Moment): Moment {
    const moment = date.add(qtd, type);
    return moment;
  }

  /**
   * Compara se uma data sobrepôe um range de datas, incluindo as datas informadas
   * @param compareDate Data a ser comparada
   * @param rangeStartDate Inicio do range
   * @param rangeEndDate Fim do range
   * @returns true caso sobreponha, false caso não
   */
  public isBetween(compareDate: Moment, rangeStartDate: Moment, rangeEndDate: Moment, granularity?: moment.unitOfTime.StartOf): boolean {
    return compareDate.isBetween(rangeStartDate, rangeEndDate, granularity, '[]');
  }

  /**
   * Cria um Objeto Moment baseado na formato de data DD/MM/YYYY
   * @param date Data - DD/MM/YYYY
   * @param hour Hora - HH:mm
   * @returns Moment
   */
  public createMomentForBrDateAndHour(date: string, hour: string): Moment {
    const splittedStartDate = date.split('/');
    const compareStartDate = this.getTimezoneDate(
      `${splittedStartDate[2]}-${splittedStartDate[1]}-${splittedStartDate[0]}T${hour}`
    );

    return compareStartDate;
  }

  /**
   * Converte uma data no formato DD/MM/YYYY, para o formato ISO YYYY/MM/DD
   * @param brDate Data - DD/MM/YYYY
   * @returns Data - YYYY/MM/DD
   */
  public convertBrDateToISO(brDate: string): string {
    const day = brDate.substring(0, 2);
    const month = brDate.substring(3, 5);
    const year = brDate.substring(6, 10);
    return `${year}-${month}-${day}`;
  }

  /**
   * Subtrai quantidade de tempo determinada do objeto Moment informado
   * @param type Unidade de medida
   * @param qtd Quantidade a ser subtraida
   * @param date Data a ser decrementada
   * @returns Retorna o date com novo valor
   */
  public subtract(type: TimeTypesEnum, qtd: number, date: Moment): Moment {
    const moment = date.subtract(qtd, type);
    return moment;
  }

  /**
   * Subtrai uma quantidade não exata de tempo, HH:mm
   * @param date Data a ser decrementada
   * @param notExactHours HH:mm
   * @returns Retorna o date com novo valor
   */
  public subtractNoExactHours(date: Moment, notExactHours: { hourDelta: number, minuteDelta: number }): Moment {
    const moment = date.subtract({ hours: notExactHours.hourDelta, minutes: notExactHours.minuteDelta });
    return moment;
  }

  /**
   * Adicionar uma quantidade não exata de tempo, HH:mm
   * @param date Data a ser incrementada
   * @param notExactHours HH:mm
   * @returns Retorna o date com novo valor
   */
  public addNoExactHours(date: Moment, notExactHours: { hourDelta: number, minuteDelta: number }): Moment {
    const moment = date.add({ hours: notExactHours.hourDelta, minutes: notExactHours.minuteDelta });
    return moment;
  }

  /**
   * Cria uma novo objeto Moment baseado na data informada
   * @param date Data informada - Seguir padrão ISO
   * @returns Moment
   */
  public getDateMoment(date: any): Moment {
    return moment(date);
  }

  public get moment() {
    return moment;
  }
}


export enum TimeTypesEnum {
  hours = 'hours',
  minutes = 'minutes',
}

export enum IonicDateEnum {
  startDate = 'startDate',
  endDate = 'endDate',
}

export enum CompareMomentEnum {
  before = '-1',
  same = '0',
  after = '1',
}
