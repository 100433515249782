import { Component, OnInit } from '@angular/core';
import { Produto, ProdutoImagem } from '@models/produto';
import { LoaderService } from '@services/loader.service';
import { ProdutoService } from '@services/produto.service';

@Component({
  selector: 'app-produto-imagem',
  templateUrl: './produto-imagem.component.html',
  styleUrls: ['./produto-imagem.component.scss']
})
export class ProdutoImagemComponent implements OnInit {

  // variavel que seta os produtos e permanece
  produtos: Produto[];
  // variavel que seta o produto imagem por vez
  produtoImagem: ProdutoImagem;
  // variavel que controla o filtro dos produtos
  produtosFiltrados: Produto[];
  // variavel que controla as colunas a serem exibidas no template
  public displayedColumns: string[] = ['ProdutoId'];

  constructor(
    public loader: LoaderService,
    private produtoSvc: ProdutoService) { }

  ngOnInit(): void {
    // define os produtos ao inicializar a tela
    this.setProdutos();
  }

  /**
   * Define os produtos consultados na api - couchDB
   */
  setProdutos(): void {
    // Exibe o loader
    this.loader.show();
    // Recupera os produtos para que seja possível realizar os filtros em tela
    this.produtoSvc.produtos.subscribe({
      next: produtoRet => {
        // padroniza os produtos recuperados da api
        const produtos = this.normalizeProduto(produtoRet);
        // seta os produtos no componente
        this.produtosFiltrados = this.produtos = produtos;
        this.loader.hide();
      },
      error: (error) => {
        console.error(error);
        this.loader.hide();
      }
    });
  }

  /**
   * Normalizador dos produtos
   * @param produtoRet parametro produto - documentos couchDB
   * @returns Produtos
   */
  normalizeProduto(produtoRet: any): Produto[] {
    produtoRet.forEach((produto: any) => {
      const produtoNormalized = {
        produtoId: produto.produtoId,
        descricao: produto.descricao
      };
      produto = produtoNormalized;
    });
    return produtoRet;
  }

  /**
   * Aplica o filtro no componente para buscar os produtos pelo parâmetro em tela
   * @param searchString serach string
   * @returns produtos filtrados
   */
  applyFilter(searchString: string): any {
    if ((this.produtos.length === 0 || searchString === undefined
      || searchString.trim() === '')) {
      return this.produtosFiltrados = this.produtos;
    }
    searchString = searchString.toLocaleLowerCase();
    this.produtosFiltrados = this.produtos.filter((v) => {
      if ((v.produtoId && v.produtoId.toLowerCase().indexOf(searchString) >= 0) ||
        (v.descricao && v.descricao.toLowerCase().indexOf(searchString) >= 0)) {
        return true;
      }
      return false;
    });
  }

  /**
   * Abre o dialog para inserir as imagens do produto
   * @param produtoId identificador do produto
   */
  async openProdutoImagem(produtoId: string): Promise<void> {
    // Consulta na api se existe produto imagem
    this.produtoSvc.getProdutoImagem(produtoId).subscribe({
      next: async (produtoImagem: any) => {
        // Em caso de existir, define a variavel com as imagens recuperadas
        this.produtoImagem = produtoImagem.imagens;
        // Abre o dialog
        await this.produtoSvc.alterarImagens(produtoId, this.produtoImagem);
      },
      error: async (error) => {
        // Em caso de não existir = error, define a variavel com uma imagem vazia
        const imagemVazia: ProdutoImagem = {
          imagens: [{ linkId: '' }]
        };
        // Abre o dialog
        await this.produtoSvc.alterarImagens(produtoId, imagemVazia);
      }
    });
  }
}
