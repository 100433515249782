import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-eventos-pre-cadastro',
  templateUrl: './eventos-pre-cadastro.component.html',
  styleUrls: ['./eventos-pre-cadastro.component.scss']
})
export class EventosPreCadastroComponent implements OnInit {
  title: string;
  labelTitle: string;
  buttonTitle: string;
  err: string;
  textarea: string;
  errControl = new UntypedFormControl('', [Validators.required]);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<any>
  ) { }

  ngOnInit(): void {
    switch (this.data.event) {
      case 'REC.PCAD':
        this.title = 'Recusar Pré-Cadastro';
        this.labelTitle = 'Motivo de Recusa';
        this.buttonTitle = 'RECUSAR';
        this.err = '*Por favor, preencha o motivo de recusa';
        break;
      case 'CON.PCAD':
        this.title = 'Confirmar Pré-Cadastro';
        this.labelTitle = 'Observação';
        this.buttonTitle = 'CONFIRMAR';
        this.err = '*Por favor, preencher o campo observação';
        break;
      case 'VER.REC':
        this.title = 'Verificar Recusa';
        this.buttonTitle = 'Ok';
        this.textarea = this.data.mensagem;
        break;
    }
  }

  getErrorMessage() {
    if (this.errControl.hasError('required')) {
      return this.err;
    }
  }

  onChangeTextarea(valueString) {
    this.textarea = valueString;
  }

  onOk() {
    if (this.textarea !== '' && this.textarea !== undefined) {
      this.dialogRef.close(this.textarea);
    }
  }
}
