<h1 mat-dialog-title>Um erro ocorreu!</h1>
<mat-dialog-content>
  @if (data.status) {
  <p>
    <b>Código do Erro: {{ data.status }}</b>
  </p>
  }
  <p>Algo deu errado!</p>
  <p>
    {{ data?.message }}
  </p>
  <p>
    {{ data?.solucao }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close class="error-close-button">
    Fechar
  </button>
</mat-dialog-actions>
