import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

export interface NovoUsuarioDialogData {
  usuario: string,
  dominio: string,
  senha: string
};

@Component({
  selector: 'app-novo-usuario-dialog',
  templateUrl: './novo-usuario-dialog.component.html',
  styleUrls: ['./novo-usuario-dialog.component.scss']
})
export class NovoUsuarioDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NovoUsuarioDialogData,
    public dialogRef: MatDialogRef<NovoUsuarioDialogComponent>,
    private toastr: ToastrService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer) {

    iconRegistry.addSvgIcon('close', sanitizer.bypassSecurityTrustResourceUrl('/assets/img/close.svg'));
  }

  onCancelar(): void {
    this.dialogRef.close(null);
  }

  onCriar(): void {
    if (this._validarUsuario(this.data)) {
      // Cria novo objeto com dados do novo usuário, ajustando nome do usuário
      const validData = {
        usuario: this.data.usuario.trim(),
        dominio: this.data.dominio,
        senha: this.data.senha,
      }
      this.dialogRef.close(validData);
    }
  }

  /**
   * Validação de usuário
   * - nome de usuário obrigatório
   * - senha obrigatório
   * @param data NovoUsuarioDialogData
   * @returns boolean
   */
  private _validarUsuario(data: NovoUsuarioDialogData) {
    if (!this.data.usuario) {
      this.toastr.error('Usuário é campo obrigatório!');
      return false;
    } else if (!this.data.senha) {
      this.toastr.error('Senha é campo obrigatório!');
      return false;
    }

    return true;
  }
}
