<div class="mat-dialog-container">
  <!-- Dialog header -->
  <div fxLayout="row" fxLayoutGap="10px">
    @if (title) {
    <h2 mat-dialog-title style="width: 100%; text-align: start">{{ title }}</h2>
    }
  </div>

  <!-- Modal content -->
  @if (data.event !== 'VER.REC') {
  <mat-dialog-content>
    <form class="form">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label> {{ labelTitle }} </mat-label>
        <textarea
          matInput
          [formControl]="errControl"
          (keyup)="onChangeTextarea($event.target.value)"
          required
        ></textarea>
        @if (errControl.invalid) {
        <mat-error>{{ getErrorMessage() }}</mat-error>
        }
      </mat-form-field>
    </form>
  </mat-dialog-content>
  } @if (data.event === 'VER.REC') {
  <mat-dialog-content>
    <form class="form">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label> {{ labelTitle }} </mat-label>
        <textarea matInput disabled>{{ textarea }}</textarea>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  }
  <!-- Modal actions -->
  <mat-dialog-actions fxLayoutAlign="end center">
    <div fxLayout="row">
      <button
        mat-flat-button
        class="btn btn-primary"
        (click)="onOk()"
        cdkFocusInitial
      >
        {{ buttonTitle }}
      </button>
      @if (data.event !== 'VER.REC') {
      <button mat-flat-button mat-dialog-close class="btn btn-danger">
        CANCELAR
      </button>
      }
    </div>
  </mat-dialog-actions>
</div>
