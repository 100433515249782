import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '../window.providers';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  static ambientes = {
    localhost: { 
      url 				   : 'http://localhost:4200',
      api_endpoint   : 'http://localhost:8080'
      // api_endpoint 	 : 'https://invoiceappapi-dev.azurewebsites.net'
      // api_endpoint 	 : 'https://invoiceappapi-tst.azurewebsites.net'
    },
  
    dev: { 
      url 				   : 'https://invoiceconapp-dev.azurewebsites.net/',
      api_endpoint   : 'https://invoiceappapi-dev.azurewebsites.net' 
    },
  
    tst: { 
      url 					 : 'https://invoiceconapp-tst.azurewebsites.net/',
      api_endpoint 	 : 'https://invoiceappapi-tst.azurewebsites.net'  
    },
  
    qas: { 
      url 					 : 'https://invoiceconapp-qas.azurewebsites.net/',
      api_endpoint 	 : 'https://invoiceappapi-qas.azurewebsites.net'  
    },
  
    hom: { 
      url 					 : 'https://invoiceconapp-hom.azurewebsites.net/',
      api_endpoint 	 : 'https://invoiceappapi-hom.azurewebsites.net'  
    },
    
    sup: { 
      url 					 : 'https://invoiceconapp-sup.azurewebsites.net',
      api_endpoint 	 : 'https://invoiceconapi-sup.azurewebsites.net/'
    },

    prd: { 
      url 					 : 'https://invoiceconapp.azurewebsites.net/',
      api_endpoint 	 : 'https://invoiceappapi.azurewebsites.net'
    }
  }

  constructor(@Inject(WINDOW) private window: Window){
    
  }

  static getEnv(forceDev: boolean = false) {
    let keys = Object.keys(this.ambientes);
    let host = window.location.hostname.toLowerCase();

    if (host == 'localhost' && forceDev) {
      host = 'dev';
    }

    for (let i = 0; i < 6; i++) {
      let env = keys[i];
      
      if(host.search(env) != -1) {
        return this.ambientes[env];
      }
    }

    // Se não encontrou o ambiente, indica PRD
    return this.ambientes.prd;
  }
  
  getEnv() {
    let keys = Object.keys(EnvService.ambientes);
    let host = window.location.hostname.toLowerCase();

    for (let i = 0; i < 6; i++) {
      let env = keys[i];
      
      if(host.search(env) != -1) {
        return EnvService.ambientes[env];
      }
    }

    // Se não encontrou o ambiente, indica PRD
    return EnvService.ambientes.prd;
  }
  
  // Método vai retornar 
  getEnvId()
  {
    let ambientes = ['localhost', 'dev', 'tst', 'qas', 'hom', 'sup'];
    let host = this.window.location.hostname.toLowerCase();
    let hostExplode = host.split('.')
    let ambInfo = {
      pre : '',
      tooltp : '',
      cor : '',
      cliente: ''
    }
    for (let i = 0; i < 6; i++) {
      let env = ambientes[i];
      
      if(host.search(env) != -1) {
        switch(env) {
          case 'localhost':
            ambInfo.pre = 'LOC';
            ambInfo.tooltp = 'Ambiente Localhost';
            ambInfo.cor = '#3A8DB8';
            ambInfo.cliente = 'cast';
            break;
          case 'dev':
            ambInfo.pre = 'DEV';
            ambInfo.tooltp = 'Ambiente de desenvolvimento';
            ambInfo.cor = '#25B84C';
            ambInfo.cliente = hostExplode[1];
            break;
          case 'tst':
            ambInfo.pre = 'TST';
            ambInfo.tooltp = 'Ambiente de teste';
            ambInfo.cor = '#c0bb1d';
            ambInfo.cliente = hostExplode[1];
            break;
          case 'qas':
            ambInfo.pre = 'QAS';
            ambInfo.tooltp = 'Ambiente de garantia da qualidade';
            ambInfo.cor = '#de8718';
            ambInfo.cliente = hostExplode[1];
            break;
          case 'hom':
            ambInfo.pre = 'HOM';
            ambInfo.tooltp = 'Ambiente de homologação';
            ambInfo.cor = '#E13D3C';
            ambInfo.cliente = hostExplode[1];
            break;
          case 'sup':
            ambInfo.pre = 'SUP';
            ambInfo.tooltp = 'Ambiente de suporte';
            ambInfo.cor = '#157475';
            break;
          default:
            ambInfo.pre = '';
            ambInfo.tooltp = '';
            ambInfo.cor = '';
            ambInfo.cliente = hostExplode[0];
        }
      }
    }

    // Se não encontrou o ambiente, retorna ambInfo vazio
    return ambInfo

  }
 
  /**
   * Retorna endereço do asset com tratamento por ambiente/container
   * @param url 
   * @returns 
   */
  static assetsUrl(url: string) {​​​​​​
    const split = window.location.pathname.split('/');
    const ambiente = split[1];
    const href = (ambiente) ? `/${ambiente}/` : '/';
    return `${href}${url}`;
  }​​​​​​
}
