import { Injectable} from '@angular/core';
import { ErroItem } from '../models/erro';
import { ErroDialogComponent } from '../dialogs/erro-dialog/erro-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErroService {
  private _err: ErroItem = new ErroItem();
  private _opened = false;

  constructor(private _dialog: MatDialog) { }

  /**
   * Seta atributos de erro no serviço
   * @param cod Código do erro
   * @param mensagem Mensagem de erro
   * @param tipo Tipo do erro
   * @param solucao Mensagem de solução/ajuda
   */
  public eventoErro(cod?: string, mensagem?: string, tipo?: string, solucao?: string) {
    const e: ErroItem = {
      cod: cod || 'ERRGERAL',
      mensagem: mensagem || 'Ocorreu um erro geral no Salescon',
      tipo: tipo || 'geral',
      solucao: solucao || 'Entre em contato com o administrador informando o erro'
    };
    this._setErroItem(e);
  }

  private _setErroItem(e: ErroItem) {
    this._err = e;
  }

  public getErroItem() {
    return this._err;
  }

  public openErrorDialog(): Observable<boolean> {
    let errorDialog;
    if (!this._opened) {
      this._opened = true;
      const message = this._err.mensagem;
      const status = this._err.cod;
      const solucao = this._err.solucao;
      errorDialog = this._dialog.open(ErroDialogComponent, {
        data: { message , status , solucao },
        maxHeight: '100%',
        width: '540px',
        maxWidth: '100%',
        disableClose: true,
        hasBackdrop: true,
      });
    }
    return new Observable( (closed) => {
      errorDialog.afterClosed().subscribe(() => {
        this._opened = false;
        closed.next(true);
      });
    });
  }
}
