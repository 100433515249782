import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { PreCadastro } from '../models/pre-cadastro';
import { PreCadastroService  } from './pre-cadastro.service';
import { catchError, finalize } from 'rxjs/operators';

export class PreCadastroDataSource implements DataSource<PreCadastro> {

  private preCadastroSubject = new BehaviorSubject<PreCadastro[]>([]);
  private preCadastroCountSubject = new BehaviorSubject<number>(0);
  private errorSubject = new BehaviorSubject<Error>(null);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public preCadastroCount$ = this.preCadastroCountSubject.asObservable();
  public error$ = this.errorSubject.asObservable();

  constructor(private preCadastroService: PreCadastroService) {

  }

  loadPreCadastro(
    areasAtuacaoId: string,
    filter: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number,
  ) {

    this.loadingSubject.next(true);

    this.preCadastroService.getPreCadastroPage(
      areasAtuacaoId,
      filter,
      sortDirection,
      pageIndex,
      pageSize
    ).pipe(
      catchError(() => {
        throw new Error('Erro ao buscar dados de pré-cadastro de parceiros');
      }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(
        preCadastros => {
          if (preCadastros) {
            this.preCadastroSubject.next(preCadastros.result);
            this.preCadastroCountSubject.next(preCadastros.count);
          }
        },
        err => {
          this.errorSubject.next(err);
        }
      );
  }

  connect(collectionViewer: CollectionViewer): Observable<PreCadastro[]> {
    return this.preCadastroSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.preCadastroSubject.complete();
    this.loadingSubject.complete();
    this.preCadastroCountSubject.complete();
    this.errorSubject.complete();
  }

}
