import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export interface ExcluirUsuarioDialogData {
  usuario: string,
};
@Component({
  selector: 'app-excluir-usuario-dialog',
  templateUrl: './excluir-usuario-dialog.component.html',
  styleUrls: ['./excluir-usuario-dialog.component.scss']
})
export class ExcluirUsuarioDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ExcluirUsuarioDialogData,
    public dialogRef: MatDialogRef<ExcluirUsuarioDialogData>,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer) {

    iconRegistry.addSvgIcon('close', sanitizer.bypassSecurityTrustResourceUrl('/assets/img/close.svg'));
  }

  ngOnInit(): void {
  }

  onCancelar(): void {
    this.dialogRef.close(null);
  }

  onExcluir(): void {
    this.dialogRef.close(this.data);
  }

}
