import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';

@Injectable()
export class MonitorPreCadastroIntlService implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel = $localize`Primeira página`;
  itemsPerPageLabel = $localize`Itens por página:`;
  lastPageLabel = $localize`Última página`;
  nextPageLabel = $localize`Próxima página`;
  previousPageLabel = $localize`Página anterior`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`0 de 0`;
    }
    const initial = page * pageSize;
    const end = initial + pageSize > length ? length : initial + pageSize;
    return $localize`${initial + 1}-${end} de ${length}`;
  }
}
