<header fxLayout="row" style="width: 100%">
  <div fxLayout="row" style="width: 100%">
    <div fxLayout="column" fxLayoutAlign="center center" style="margin: 10px">
      <img
        style="padding: 7px"
        style="cursor: pointer; border: 0; outline: 0"
        src="{{ logoUrl }}"
        alt=""
        (click)="goHome()"
        (load)="loadBadgeAmbiente()"
      />
    </div>

    @if (ambiente.pre) {
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-chip-set>
        <mat-chip
          selected
          [ngStyle]="{ 'background-color': ambiente.cor }"
          matTooltip="{{ ambiente.tooltip }}"
        >
          <span style="text-align: center">{{ ambiente.pre }}</span>
        </mat-chip>
      </mat-chip-set>
    </div>
    } @if (isLogged) {
    <span class="container-header">
      @if(fetchingMenu){
      <mat-spinner diameter="40"></mat-spinner>
      }
      <!-- Percorrendo os itens do menu -->
      @for (item of menu; track $index) {

      <div style="margin-top: 4px">
        <!-- Caso o item tenha sub-itens -->
        @if (item.subMenu.length > 0) {
        <div>
          @if (visualizarMenu(item.id) && item.ativo) {
          <button
            mat-button
            [class]="
              item.selecionado
                ? 'selected button-header menuItem'
                : 'button-header menuItem'
            "
            [id]="item.id"
            [matMenuTriggerFor]="menuFor"
            routerLinkActive=" router-link-active"
          >
            <mat-icon
              class="icon-inv"
              aria-hidden="false"
              style="margin-right: 10px"
            >
              {{ item.icon }}
            </mat-icon>
            {{ item.titulo }}
          </button>
          }
          <!-- Sub-itens -->
          <mat-menu #menuFor="matMenu">
            @for (subMenu of item.subMenu; track subMenu) {
            <div>
              @if (visualizarSubMenu(subMenu.id) && subMenu.ativo) {
              <button
                mat-menu-item
                [routerLink]="[subMenu.rota]"
                routerLinkActive="router-link-active"
              >
                {{ subMenu.titulo }}
              </button>
              }
            </div>
            }
          </mat-menu>
        </div>
        }
        <!-- Caso o item seja apenas uma rota -->
        @if (item.subMenu.length === 0) {
        <div>
          @if (visualizarMenu(item.id) && item.ativo) {
          <button
            [class]="
              item.selecionado
                ? 'selected button-header menuItem'
                : 'button-header menuItem'
            "
            [id]="item.id"
            [routerLink]="[item.rota]"
            routerLinkActive=" router-link-active"
            mat-button
          >
            <mat-icon
              class="icon-inv"
              aria-hidden="false"
              style="margin-right: 10px"
            >
              {{ item.icon }}
            </mat-icon>
            {{ item.titulo }}
          </button>
          }
        </div>
        }
        <!-- Fim menu -->
      </div>
      }
    </span>
    }
    <!-- Menu do usuário -->
    @if (!isLogged) {
    <button
      mat-flat-button
      [style.background-color]="btnColor"
      (click)="login()"
      style="margin: auto; margin-right: 17px"
    >
      <span>Login</span>
    </button>
    } @if (isLogged) {
    <button
      mat-button
      [matMenuTriggerFor]="userMenu"
      style="display: flex; margin: auto; margin-right: 17px"
    >
      <mat-icon class="icon-inv" aria-hidden="false">person</mat-icon>
      <span>{{ username }}</span>
      <mat-icon class="icon-inv" aria-hidden="false"
        >keyboard_arrow_down</mat-icon
      >
    </button>
    }

    <!-- Itens do menu usuário -->
    <mat-menu #userMenu="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="menuTheme">Temas</button>
      <button
        mat-menu-item
        [routerLink]="['/logout']"
        routerLinkActive="router-link-active"
      >
        Sair
      </button>
    </mat-menu>

    <!-- Itens tema -->
    <mat-menu #menuTheme="matMenu">
      @for (tema of temas; track $index) {
      <button mat-menu-item (click)="themeHandler(tema.nome)">
        {{ tema.descricao }}
      </button>
      }
    </mat-menu>
  </div>
</header>
