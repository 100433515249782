<div class="container" fxLayout="column">
  @if (data) {
  <mat-expansion-panel class="card" [expanded]="true" style="margin-top: -25px">
    <mat-dialog-content>
      <!-- Dialog header -->
      <div fxLayout="row" fxLayoutGap="10px">
        <h2 mat-dialog-title style="width: 100%; text-align: start">
          Imagens {{ data.produtoId }}
          &nbsp;
          <button mat-mini-fab color="primary" (click)="onClickAdd()">
            <mat-icon>add</mat-icon>
          </button>
        </h2>
      </div>
      <!-- Modal content -->
      <form [formGroup]="dynamicForm" (keydown.enter)="$event.preventDefault()">
        <div fxLayout="column">
          @for (imagem of imagens.controls; track i; let i = $index) {
          <div>
            <div [formGroup]="imagem" fxLayout="row">
              <button
                matPrefix
                mat-mini-fab
                color="warn"
                (click)="onClickDelete(i)"
              >
                <mat-icon>remove</mat-icon>
              </button>
              &nbsp;
              <mat-form-field fxFlex="grow">
                <mat-label>URL Imagem</mat-label>
                <input matInput type="text" formControlName="linkId" required />
              </mat-form-field>
            </div>
          </div>
          }
        </div>
      </form>
    </mat-dialog-content>
    <!-- Modal actions -->
    <mat-dialog-actions fxLayoutAlign="end center">
      <div fxLayout="row">
        <button
          mat-flat-button
          class="btn btn-primary"
          type="submit"
          (click)="onSalvar()"
          cdkFocusInitial
        >
          SALVAR
        </button>
        <button mat-flat-button class="btn btn-danger" (click)="onCancelar()">
          CANCELAR
        </button>
      </div>
    </mat-dialog-actions>
  </mat-expansion-panel>
  }
</div>
