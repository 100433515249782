import { Inject, Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

import { ConfigService } from './config.service';
import { ErroService } from './erro.service';
import { BehaviorSubject } from 'rxjs';

export interface Permissao {
  slug: string;
  name: string;
  description: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isADUser: boolean;
  protected isLogged: boolean;
  private isLoggedSubject = new BehaviorSubject<boolean>(false);

  public isLogged$ = this.isLoggedSubject.asObservable();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private configService: ConfigService,
    private erroService: ErroService
  ) {}

  /**
   * Define situação de usuário logado/deslogado
   * @param isLogged
   */
  public setLogged(isLogged: boolean) {
    this.isLoggedSubject.next(isLogged);
  }

  public getUserAttibute(attribute: string): string {
    const activeAccount = this._getActiveAccount();
    if (activeAccount) {
      try {
        return decodeURIComponent(escape(activeAccount[attribute]));
      } catch (err) {
        return activeAccount[attribute];
      }
    }
    return '';
  }

  private _getActiveAccount() {
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
    return activeAccount;
  }

  /**
   * Verificar se usuário do AD é valido e com grupo configurado
   * @returns
   */
  public async checkValidADUser(): Promise<boolean> {
    if (this.isADUser === undefined) {
      const group = await this.getUserGroup();
      if (group && group.startsWith('USERS_')) {
        this.isADUser = true;
        return true;
      }

      this.isADUser = false;
      return false;
    }

    if (this.isADUser) {
      return true;
    }
    return false;
  }

  /**
   * Recuperar grupo do usuário logado
   * @returns
   */
  private async getUserGroup(): Promise<string> {
    try {
      const { grupo } = await this.configService.getConfig();
      return grupo;
    } catch (error) {
      console.error('Erro ao recuperar grupo do usuário.')
      this.erroService.eventoErro(
        'ERR.GROUP',
        'Erro ao recuperar grupo do usuário.'
      );
    }
  }

  /**
   * Realizar o login usando o msal-angular
   */
  public openMSLogin() {
    if (this.msalGuardConfig.authRequest){
      this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

}
