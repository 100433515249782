import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoaderService } from '@services/loader.service';
import { FormularioService } from '@services/formulario.service';
import { FormStepper } from '@models/form-stepper';
import { TimeHandlerService } from '@services/time-handler.service';

@Component({
  selector: 'app-formularios',
  templateUrl: './formularios.page.html',
  styleUrls: ['./formularios.page.scss']
})
export class FormulariosPage implements OnInit {
  // variavel que seta os formulários e permanece
  formularios: FormStepper[] = [];
  // variavel que controla o filtro dos formulários
  formulariosFiltrados: FormStepper[] = [];

  private routeRoot = '/admin/formularios';

  constructor(
    public loader: LoaderService,
    private router: Router,
    private formularioService: FormularioService,
    private timeHandlerService: TimeHandlerService,
  ) { }

  ngOnInit(): void {
    // define os formulários ao inicializar a tela
    this._setFormularios();
  }

  /**
   * Aplica o filtro de acordo com o valor inserido pelo usuário
   * @param filterValue filtro
   */
  applyFilter(filterValue: string) {
    filterValue = filterValue?.toLowerCase();
    // Colunas utilizadas para filtrar
    const columns = ['id', 'nome'];
    // Função que realiza o filtro
    let filterFunc = (v: FormStepper) => {
      const filters = {};
      columns.forEach(column => {
        filters[column] = v.dadosGerais[column] && v.dadosGerais[column].toLowerCase().indexOf(filterValue) >= 0;
      });
      // Verifica se alguma propriedade é true
      return Object.keys(filters).some((col) => filters[col]);
    };

    if (filterValue) {
      this.formulariosFiltrados = this.formularios.filter(filterFunc);
    } else {
      this.formulariosFiltrados = JSON.parse(JSON.stringify(this.formularios));
    }
  }

  /**
   * Define os formulários consultados na api - couchDB
   */
  private _setFormularios(): void {
    this.loader.show();
    // Recupera os formulários para que seja possível realizar os filtros em tela
    this.formularioService.formularios.subscribe({
      next: formulariosRet => {
        // seta os formulários no componente
        this.formulariosFiltrados = this.formularios = formulariosRet.sort((a, b) => {
          const dtA = new Date(a.dataModificacao || a.dataCriacao);
          const dtB = new Date(b.dataModificacao || b.dataCriacao);
          return dtB.getTime() - dtA.getTime();
        });
        this.loader.hide();
      },
      error: (error) => {
        console.error(error);
        this.loader.hide();
      }
    });
  }

  /**
   * Ao criar
   * Envia o user para página de criação de formulário
   */
  public async onNew() {
    let url = `${this.routeRoot}/criar`;
    await this.router.navigateByUrl(url);
  }

  /**
   * Ao editar
   * Envia o user para página de edição de formulário
   */
  public async onEdit(formulario: FormStepper) {
    this.formularioService.editFormulario(formulario);
    let url = `${this.routeRoot}/editar/${formulario.dadosGerais.id}`;
    await this.router.navigateByUrl(url);
  }

  /**
   * Converte uma data ISO em BR
   * @param formulario Form
   * @returns data formatada
   */
  public toDateBr(formulario: FormStepper) {
    const date = formulario?.dataModificacao || formulario?.dataCriacao;
    return this.timeHandlerService.formatDate(date, 'DD/MM/YYYY');
  }

}
