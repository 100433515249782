import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';

import { LoaderService } from './services/loader.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'salescon-fend';
  isIframe = false;
  public showLoader = true;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private loader: LoaderService,
  ) { }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      })
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  ngAfterViewInit(): void {
    // Fix do loader - previne o erro que ocorria no console ao trocar de telas
    this.loader.getLoader()
      .pipe(
        delay(0)
      ).subscribe({
        next: loader => {
          this.showLoader = loader;
        },
        error: (error) => {
          console.log(error);
        }
      });
  }

  /**
   * Verifica e define conta ativa
   */
  checkAndSetActiveAccount(){
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      this.authService.setLogged(true);
    }

    // Informa ao serviço se usuário está autenticado
    if (activeAccount) {
      this.authService.setLogged(true);
    } else {
      this.authService.setLogged(false);
    }
  }

  /**
   * Função para mudar o tema.
   */
  mudarTema(tema: string) {
    this.trans();
    document.documentElement.setAttribute('data-theme', tema);
    localStorage.setItem('temaAplicacao', tema);
  }

  /**
   * Função para fazer a mudança de tema com transição
   */
  trans() {
    document.documentElement.classList.add('transition');
    window.setTimeout(() => {
      document.documentElement.classList.remove('transition');
    }, 600);
  }
}
