import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderService } from '@services/loader.service';
import { PreCadastroService } from '@services/pre-cadastro.service';

const statusBotoes = {
  'CAD.ENV': { verificar: false, recusar: true, confirmar: true },
  'CAD.REC': { verificar: true, recusar: false, confirmar: false },
  'CAD.OK': { verificar: false, recusar: false, confirmar: false },
};

@Component({
  selector: 'app-monitor-pre-cadastro-detalhe',
  templateUrl: './monitor-pre-cadastro-detalhe.component.html',
  styleUrls: ['./monitor-pre-cadastro-detalhe.component.scss']
})
export class MonitorPreCadastroDetalheComponent implements OnInit {
  title: any;
  eventResult: any;
  public displayedColumns: string[] = ['Nome', 'Telefone', 'Celular', 'Email', 'Observacao'];
  mostrarBotoes = {
    verificar: false,
    recusar: false,
    confirmar: false
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private preCadastro: PreCadastroService,
    public dialogRef: MatDialogRef<any>,
    private loader: LoaderService
  ) {
    iconRegistry.addSvgIcon('search',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/img/search.svg'));
  }

  async ngOnInit() {
    await this.data;
    await this.onInit();
  }

  async onInit() {
    this.data = this.data.dataPicked;
    this.title = `${this.data.representanteId}-${this.data.descricao}`;
    this.handleButtons(this.data.situacao);
  }

  handleButtons(situacao) {
    this.mostrarBotoes = statusBotoes[situacao];
  }

  applyFilter(value) {

  }

  async onRecusarCadastro() {
    await this.performeEvento('REC.PCAD');
    if (this.eventResult) {
      this.dialogRef.close(this.eventResult);
    }
  }

  async onConfirmarCadastro() {
    await this.performeEvento('CON.PCAD');
    if (this.eventResult) {
      this.dialogRef.close(this.eventResult);
    }
  }

  async onVerificarRecusa() {
    await this.performeEvento('VER.REC');
  }

  async performeEvento(situacao) {
    this.eventResult = await this.preCadastro.performeEvento(situacao, this.data);
    if (situacao !== 'VER.REC') {
      this.data = this.eventResult ? this.eventResult : this.data;
    }
  }

  mascaraCNPJ(cnpj) {
    if (cnpj) {
      return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5');
    }
  }

  mascaraCPF(cpf) {
    if (cpf) {
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
    }
  }

  onOk() {
    this.dialogRef.close(this.eventResult);
  }
}
